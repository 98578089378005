import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Button } from "@form-elements/Button";
import Input from "@/components/form-elements/Input";
import MemoLoading from "@assets/SVG/Loading";
import { useRouter } from "next/navigation";
import { useStytch, useStytchSession } from "@stytch/nextjs";
import cookie from "js-cookie";
import { getUserByEmail } from "@services/search";
import { passwordValidator } from "@/types/util";
import { trackEvent } from "@app/analytics/mixpanel";

export default function PasswordInput({
    email,
    setErrorType,
    setSuccess,
    setUserHasAPassword,
    showConfirmPassword,
}: {
    email: string;
    setErrorType: Dispatch<SetStateAction<string>>;
    setSuccess: Dispatch<SetStateAction<boolean>>;
    setUserHasAPassword: Dispatch<SetStateAction<boolean>>;
    showConfirmPassword: boolean;
}) {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const router = useRouter();
    const stytchClient = useStytch();
    const { session } = useStytchSession();

    const handlePasswordSignUp = useCallback(
        (e) => {
            e.preventDefault();

            if (
                !password ||
                !!passwordError ||
                (showConfirmPassword && (!confirmPassword || !!confirmPasswordError))
            ) {
                return;
            }

            try {
                setLoading(true);
                getUserByEmail({ email: email }).then((res) => {
                    setLoading(true);
                    const data = res.response.results;

                    if (data?.[0]?.password?.password_id) {
                        // user exists with password

                        stytchClient.passwords
                            .authenticate({
                                email: email,
                                password: password,
                                session_duration_minutes: 10080,
                            })
                            .then((response) => {
                                cookie.set("session_token", response.session_token);
                                cookie.set("session_jwt", response.session_jwt);
                                setErrorType("");
                                trackEvent("Sign In", {
                                    type: "Password",
                                    user_id: response.user_id,
                                });
                            })
                            .catch((error) => {
                                const isReset = error?.error_type === "reset_password";
                                const isWeak = error?.error_type === "weak_password";
                                const isUnauthorized = error?.error_type === "unauthorized_credentials";

                                if (isReset) {
                                    setErrorType("reset_password");
                                    setLoading(false);
                                    setSuccess(false);
                                    router.push(`/reset-password?email=${email}`);
                                } else if (isWeak) {
                                    setErrorType("weak_password");
                                    setLoading(false);
                                    setSuccess(false);
                                } else if (isUnauthorized) {
                                    setErrorType("unauthorized_credentials");
                                    setLoading(false);
                                    setSuccess(false);
                                    if (session) {
                                        stytchClient.session.revoke().then(() => {
                                            setErrorType("");
                                        });
                                    }
                                }
                            });
                    } else {
                        stytchClient.passwords
                            .resetBySession({
                                password: password,
                                session_duration_minutes: 10080,
                            })
                            .then(() => {
                                setLoading(false);
                                setUserHasAPassword(true);
                                // this is only for existing users with a valid session already
                                // hence, they must have logged in already
                                // hence, mixpanel analytics must have been caught
                            })
                            .catch(() => {
                                setLoading(false);
                                setSuccess(false);
                            });
                    }
                });
            } catch (error) {
                setLoading(false);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [stytchClient, email, password],
    );

    useEffect(() => {
        passwordValidator(password, setPasswordError);

        if (confirmPassword) {
            if (password !== confirmPassword) {
                return setConfirmPasswordError("Password must match the one above");
            }
            setConfirmPasswordError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, confirmPassword]);

    return (
        <form onSubmit={handlePasswordSignUp} className="flex w-full flex-col gap-3">
            <Input
                className={`${
                    password && !passwordError && "border-secondary"
                } h-10 gap-2 rounded-lg border-dark-blue p-3 text-xs outline-0 focus:outline-0`}
                placeholder="Your Password"
                input={password}
                setInput={setPassword}
                type="password"
                showEyeIcon={true}
                error={passwordError}
                name="password"
                autoComplete="password"
            />

            {showConfirmPassword && (
                <Input
                    className={`${
                        confirmPassword && !confirmPasswordError && "border-secondary"
                    } h-10 gap-2 rounded-lg border-dark-blue p-3 text-xs outline-0 focus:outline-0`}
                    placeholder="Please Confirm Password"
                    input={confirmPassword}
                    setInput={setConfirmPassword}
                    type="password"
                    showEyeIcon={true}
                    error={confirmPasswordError}
                    name="password"
                    autoComplete="password"
                />
            )}

            <Button
                onClick={handlePasswordSignUp}
                disabled={
                    !password ||
                    !!passwordError ||
                    (showConfirmPassword && (!confirmPassword || !!confirmPasswordError))
                }
                className="flex h-10 w-full items-center justify-center gap-2.5 rounded-lg border-none bg-powder-blue px-2.5 py-3"
            >
                <p className="text-xs font-normal text-white">
                    {loading ? <MemoLoading className="w-4" /> : "Enter Terminal"}
                </p>
            </Button>
        </form>
    );
}

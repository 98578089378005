import playSound from "@/components/sound";
import { useVolume } from "@app/dashboard/VolumeContext";
import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import ReactSlider from "react-slider";

export default function VolumeControl() {
    const { volume, setVolume } = useVolume();
    const [volumeBeforeMute, setVolumeBeforeMute] = useState(0);

    const handleMute = () => {
        if (volume === 0) {
            setVolume(volumeBeforeMute);
        } else {
            setVolumeBeforeMute(volume);
            setVolume(0);
        }
    };

    return (
        <div className="relative flex w-full items-center gap-2 px-1">
            <ReactSlider
                className="horizontal-slider h-10 cursor-pointer hover:brightness-75"
                thumbClassName={`example-thumb volume-thumb ${volume === 0 && "thumb-mute"}`}
                trackClassName={`example-track volume-track ${volume === 0 ? "track-mute" : "track-unmute"}`}
                onChange={(value) => setVolume(value / 100)}
                onAfterChange={(value) => playSound(value / 100)}
                defaultValue={volume * 100}
            />
            <div onClick={handleMute} className="size-3.5 cursor-pointer text-light-gray hover:brightness-75">
                {volume === 0 ? (
                    <SpeakerXMarkIcon className="size-full" />
                ) : (
                    <SpeakerWaveIcon className="size-full" />
                )}
            </div>
        </div>
    );
}

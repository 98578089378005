import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "@form-elements/Button";
import Input from "@/components/form-elements/Input";
import { getUserByEmail } from "@/services/search";
import { useStytch } from "@stytch/nextjs";
import { isEmpty } from "lodash";
import MemoLoading from "@assets/SVG/Loading";
import { emailValidator } from "@/types/util";

export default function EmailInput({
    email,
    setEmail,
    setShowPasswordInput,
    setMethodId,
    setShowOTPInput,
    setSuccess,
    setErrorType,
    setShowConfirmPassword,
}: {
    email: string;
    setEmail: Dispatch<SetStateAction<string>>;
    setShowPasswordInput: Dispatch<SetStateAction<boolean>>;
    setMethodId: Dispatch<SetStateAction<string>>;
    setShowOTPInput: Dispatch<SetStateAction<boolean>>;
    setSuccess: Dispatch<SetStateAction<boolean>>;
    setErrorType: Dispatch<SetStateAction<string>>;
    setShowConfirmPassword: Dispatch<SetStateAction<boolean>>;
}) {
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState("");

    useEffect(() => {
        emailValidator(email, setEmailError);
    }, [email]);

    const stytchClient = useStytch();

    const checkExistingUser = (e) => {
        e.preventDefault();
        if (!email || !!emailError) {
            return;
        }

        getUserByEmail({ email: email }).then((res) => {
            setLoading(true);
            const data = res.response.results;
            setSuccess(false);

            if (!isEmpty(data)) {
                setShowConfirmPassword(false);
                if (!data?.[0]?.password?.password_id) {
                    return stytchClient.passwords
                        .resetByEmailStart({
                            email: email,
                            reset_password_expiration_minutes: 60,
                            reset_password_redirect_url: `${window.location.origin}/create-new-password`,
                            reset_password_template_id: "custom_password_reset",
                            login_redirect_url: `${window.location.origin}/authenticate?type=eml`,
                            login_expiration_minutes: 10080,
                        })
                        .then(() => {
                            setLoading(false);
                            setSuccess(true);
                            setErrorType("");
                        })
                        .catch(() => {
                            setErrorType("too_many_requests");
                            setLoading(false);
                            setSuccess(false);
                        });
                }

                const verified = data?.[0]?.emails?.[0]?.verified;

                if (!verified) {
                    setLoading(false);
                    setShowOTPInput(true);
                } else {
                    setLoading(false);
                    setShowPasswordInput(true);
                }
            } else
                stytchClient.otps.email.loginOrCreate(email).then((res) => {
                    setMethodId(res?.method_id);
                    setLoading(false);
                    setShowOTPInput(true);
                });
        });
    };

    return (
        <form onSubmit={checkExistingUser} className="flex w-full flex-col gap-3">
            <Input
                className={`${email && !emailError && "border-secondary focus:border-secondary"} ${
                    email && emailError && "border-light-red focus:border-light-red"
                } h-10 gap-2 rounded-lg border-dark-blue p-3 pr-10 text-xs focus:outline-0 focus:ring-0`}
                placeholder="Your Email"
                input={email}
                setInput={setEmail}
                error={emailError}
                type="email"
                name="email"
                autoComplete="email"
            />

            <Button
                type="submit"
                onClick={checkExistingUser}
                disabled={!email || !!emailError}
                className="flex h-10 w-full items-center justify-center gap-2.5 rounded-lg border-none bg-powder-blue px-2.5 py-3"
            >
                {loading ? (
                    <MemoLoading className="w-4" />
                ) : (
                    <p className="text-xs font-normal text-white">Proceed</p>
                )}
            </Button>
        </form>
    );
}

import { Dispatch, SetStateAction } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useUserSubscription } from "@/services/payment";
import { Popover } from "react-tiny-popover";

const ProDetails = () => {
    return (
        <div className="flex w-60 flex-col items-start rounded-lg border-b border-dark-blue bg-royal-blue px-0 py-1">
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <CheckCircleIcon className="size-6 text-secondary" />
                    <p className="text-xs font-light text-light-blue">News Feed</p>
                </div>
                <p className="text-xs font-normal text-white">Real Time</p>
            </div>
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <CheckCircleIcon className="size-6 text-secondary" />
                    <p className="text-xs font-light text-light-blue">Pricing Data</p>
                </div>
                <p className="text-xs font-normal text-white">Real Time</p>
            </div>
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <CheckCircleIcon className="size-6 text-secondary" />
                    <p className="text-xs font-light text-light-blue">Search</p>
                </div>
                <p className="text-xs font-normal text-white">Unlocked</p>
            </div>
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <CheckCircleIcon className="size-6 text-secondary" />
                    <p className="text-xs font-light text-light-blue">Custom Filter Sets</p>
                </div>
                <p className="text-xs font-normal text-white">Unlocked</p>
            </div>
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <XCircleIcon className="size-6 text-light-red" />
                    <p className="text-xs font-light text-light-blue">Custom Watchlists</p>
                </div>
                <p className="text-xs font-normal text-white">Locked</p>
            </div>
        </div>
    );
};

const BasicDetails = () => {
    return (
        <div className="flex w-60 flex-col items-start rounded-lg border-b border-dark-blue bg-royal-blue px-0 py-1">
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <CheckCircleIcon className="size-6 text-secondary" />
                    <p className="text-xs font-light text-light-blue">News Feed</p>
                </div>
                <p className="text-xs font-normal text-white">Real Time</p>
            </div>
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <CheckCircleIcon className="size-6 text-secondary" />
                    <p className="text-xs font-light text-light-blue">Pricing Data</p>
                </div>
                <p className="text-xs font-normal text-white">Real Time</p>
            </div>
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <XCircleIcon className="size-6 text-light-red" />
                    <p className="text-xs font-light text-light-blue">Search</p>
                </div>
                <p className="text-xs font-normal text-white">Locked</p>
            </div>
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <XCircleIcon className="size-6 text-light-red" />
                    <p className="text-xs font-light text-light-blue">Custom Filter Sets</p>
                </div>
                <p className="text-xs font-normal text-white">Locked</p>
            </div>
            <div className="flex w-full items-center justify-between gap-2 p-2">
                <div className="flex items-center gap-1 p-0">
                    <XCircleIcon className="size-6 text-light-red" />
                    <p className="text-xs font-light text-light-blue">Custom Watchlists</p>
                </div>
                <p className="text-xs font-normal text-white">Locked</p>
            </div>
        </div>
    );
};

export default function SubscriptionPerks({
    isOpen,
    setIsOpen,
    profilePopover,
    setProfilePopover,
}: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    profilePopover: boolean;
    setProfilePopover: Dispatch<SetStateAction<boolean>>;
}) {
    const { data } = useUserSubscription();

    const userIsPro = data?.subIsActive;

    return (
        <Popover
            isOpen={isOpen}
            positions={["bottom", "top", "left", "right"]} // preferred positions by priority
            content={userIsPro ? <ProDetails /> : <BasicDetails />}
            onClickOutside={() => setIsOpen(false)}
            containerClassName="z-[1]"
            transformMode="relative"
            transform={{ top: 10, left: -30 }}
        >
            <div
                onPointerEnter={() => {
                    if (profilePopover) setProfilePopover(false);
                    setIsOpen(true);
                }}
                onPointerLeave={() => setIsOpen(false)}
                onClick={() => {
                    if (profilePopover) setProfilePopover(false);
                    setIsOpen(!isOpen);
                }}
                className="relative hidden cursor-default items-center gap-2 px-1 lg:flex"
            >
                {userIsPro ? (
                    <>
                        <div className="size-2 animate-pulse rounded-full bg-secondary" />
                        <p className="text-xs font-light text-cerulean-blue">Live Data</p>
                    </>
                ) : (
                    <>
                        <div className="size-2 animate-pulse rounded-full bg-light-orange" />
                        <p className="text-xs font-light text-light-blue">Limited Access</p>
                        <InformationCircleIcon className="size-3 text-cerulean-blue" />
                    </>
                )}
            </div>
        </Popover>
    );
}

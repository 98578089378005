import { UserCircleIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";
import { Popover } from "react-tiny-popover";
import { UserDropdownModal } from "./profile/UserDropdownModal";

export function ProfileButton({
    isOpen,
    setIsOpen,
    isDetailsPopover,
    setDetailsPopover,
}: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    isDetailsPopover: boolean;
    setDetailsPopover: Dispatch<SetStateAction<boolean>>;
}) {
    return (
        <Popover
            isOpen={isOpen}
            positions={["bottom", "top", "left", "right"]} // preferred positions by priority
            content={<UserDropdownModal setOpen={setIsOpen} />}
            onClickOutside={() => setIsOpen(false)}
            containerClassName="z-[1]"
        >
            <UserCircleIcon
                onPointerEnter={() => {
                    if (isDetailsPopover) setDetailsPopover(false);
                    setIsOpen(true);
                }}
                onClick={() => {
                    if (isDetailsPopover) setDetailsPopover(false);
                    setIsOpen(false);
                }}
                className="size-[17px] cursor-pointer text-neutral hover:brightness-75"
            />
        </Popover>
    );
}

"use client";

import NotificationButton from "@app/NotificationButton";
import { ProfileButton } from "@app/ProfileButton";
import { NotificationModal } from "@app/NotificationModal";
import { useEffect, useState } from "react";
import useNotifications from "@app/useNotifications";
import { AuthenticationModal } from "@app/auth/AuthenticationModal";
// import { TIER } from "@/types/api";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useStytchSession, useStytchUser } from "@stytch/nextjs";
import MemoLoading from "@assets/SVG/Loading";
// import { WelcomeModal } from "@app/dashboard/WelcomeModal";
import useLocalStorageState from "use-local-storage-state";
import { useSnapshot } from "valtio";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";
// import { getReferredUsersList, getVerifiedAccessListContacts } from "@/services/email";
import { globalProxy } from "@/store/globalStore";
import { UpgradeModal } from "@app/dashboard/news-feed/news-list/UpgradeModal";
import { useUserSubscription } from "@/services/payment";
import SubscriptionPerks from "./SubscriptionPerks";
// import NewsItemModal from "@app/dashboard/[articleId]/news-detail/NewsItemModal";

const SessionControls = () => {
    const [showSignIn, setShowSignIn] = useState(false);
    const [notifications, reset] = useNotifications();
    const [showNotifications, setShowNotifications] = useState(false);
    // const [showAccessModal, setShowAccessModal] = useState(false);
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isProfilePopoverOpen, setIsProfilePopoverOpen] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);

    const { session, isInitialized } = useStytchSession();
    const { data, isLoading: userPaymentIsLoading } = useUserSubscription();
    const { user } = useStytchUser();
    const [firstVisit, setFirstVisit] = useLocalStorageState("firstVisit", {
        defaultValue: Date.now(),
    });
    const modalGlobalProxy = useSnapshot(globalProxy);
    const isNewsModalOpen = modalGlobalProxy.isNewsModalOpen;

    const userIsPro = data?.subIsActive;
    const userIsVerified = session && !!user?.password?.password_id && user?.emails?.[0]?.verified;
    const sessionIsOauth = session && session?.authentication_factors[0].type === "oauth";
    const sessionIsMgicLink = session && session?.authentication_factors[0].type === "magic_link";

    const [userHasAPassword, setUserHasAPassword] = useState(userIsVerified as boolean);

    const LOCKOUT_DURATION_MS = 15000; // 15 seconds

    useEffect(() => {
        if (!session) {
            setUserHasAPassword(false);
            if (firstVisit) {
                const elapsedTime = Date.now() - firstVisit;
                if (elapsedTime > LOCKOUT_DURATION_MS) {
                    setShowSignIn(true);
                }
            } else {
                const timer = setTimeout(() => setShowSignIn(true), 3000);
                return () => clearTimeout(timer);
            }
        }
    }, [session, firstVisit]);

    // useEffect(() => {

    //     const user_email = user?.emails?.[0]?.email;
    //     if (user_email) {
    //         getVerifiedAccessListContacts(jwt).then((res) => {
    //             const verifiedList = res;
    //             const userIsVerified: any = verifiedList?.some((user: any) => user?.email === user_email);
    //             if (userIsVerified) {
    //                 setShowAccessModal(false);
    //             } else {
    //                 getReferredUsersList(jwt).then((res) => {
    //                     const referredUsers = res;
    //                     const userIsOnReferredList: any = referredUsers?.some(
    //                         (user: any) => user === user_email,
    //                     );
    //                     if (userIsOnReferredList) {
    //                         setShowAccessModal(false);
    //                     } else {
    //                         setShowAccessModal(true);
    //                     }
    //                 });
    //             }
    //         });
    //     }
    // }, [user, session]);

    return (
        <>
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            {!isInitialized || userPaymentIsLoading ? (
                <MemoLoading className="w-4" />
            ) : userIsVerified || sessionIsOauth || sessionIsMgicLink || userHasAPassword ? (
                <div className="relative flex items-center gap-4 px-2">
                    {showNotifications && (
                        <NotificationModal
                            notifications={notifications}
                            clearNotifications={() => {
                                setShowNotifications(false);
                                reset();
                            }}
                            show={showNotifications}
                            setShow={setShowNotifications}
                        />
                    )}
                    <NotificationButton notifications={notifications} setShow={setShowNotifications} />
                    <ProfileButton
                        isOpen={isProfilePopoverOpen}
                        setIsOpen={setIsProfilePopoverOpen}
                        isDetailsPopover={isPopoverOpen}
                        setDetailsPopover={setIsPopoverOpen}
                    />
                    {/* {showAccessModal && <WelcomeModal show={showAccessModal} setShow={setShowAccessModal} />} */}

                    {!userIsPro && (
                        <UpgradeModal
                            show={showUpgrade}
                            setShow={setShowUpgrade}
                            setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                        >
                            <div
                                onClick={() => {
                                    setShowUpgrade(true);
                                }}
                                className="cursor-pointer rounded-3xl border-none bg-powder-blue px-2 py-1 hover:brightness-75"
                            >
                                <p className="whitespace-normal text-xs font-normal text-white">
                                    Upgrade Now
                                </p>
                            </div>
                        </UpgradeModal>
                    )}
                    <div className="hidden h-0 w-4 rotate-90 border border-solid border-dark-slate-blue lg:flex" />
                    <SubscriptionPerks
                        isOpen={isPopoverOpen}
                        setIsOpen={setIsPopoverOpen}
                        profilePopover={isProfilePopoverOpen}
                        setProfilePopover={setIsProfilePopoverOpen}
                    />
                </div>
            ) : (
                <>
                    {showSignIn && !isNewsModalOpen && (
                        <AuthenticationModal
                            show={showSignIn}
                            setShow={setShowSignIn}
                            setUserHasAPassword={setUserHasAPassword}
                        />
                    )}

                    {/* <NewsItemModal /> */}

                    <LoginSignupLink setShow={setShowSignIn} />
                </>
            )}
        </>
    );
};

const LoginSignupLink = ({ setShow }) => (
    <div
        onClick={() => setShow(true)}
        className="flex cursor-pointer items-center gap-2 px-1 py-4 hover:brightness-75"
    >
        <div className="flex size-5 items-center justify-center">
            <UserCircleIcon className="size-[17px] text-powder-blue" />
        </div>
        <p className="w-auto text-xs font-normal text-primary">Login / Sign Up</p>
    </div>
);

export default SessionControls;

"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { Squares2X2Icon } from "@heroicons/react/24/outline";

export const DashboardLink = () => {
    const pathname = usePathname();
    return (
        <Link href="/dashboard">
            <div
                className={`flex cursor-pointer items-center text-[14px] leading-4 hover:brightness-75 ${
                    pathname?.includes("/dashboard")
                        ? "font-normal text-white"
                        : "font-light text-cerulean-blue"
                }`}
            >
                <Squares2X2Icon
                    className={`${
                        pathname?.includes("/dashboard") ? "text-white" : "text-neutral"
                    } mr-3 size-5`}
                    strokeWidth={pathname?.includes("/dashboard") ? 2.0 : 1.5}
                />
                <p>Dashboard</p>
            </div>
        </Link>
    );
};

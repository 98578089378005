import { BellIcon } from "@heroicons/react/24/outline";

export default function NotificationButton({ notifications, setShow }) {
    return (
        <div
            onClick={() => {
                setShow(true);
            }}
            className={`${
                notifications?.length > 0 && "animate-[tilt-shaking_0.25s_infinite]"
            } relative cursor-pointer hover:brightness-75`}
        >
            <BellIcon className="size-[17px] text-neutral" />
            {notifications.length > 0 && (
                <div className="absolute -top-0.5 right-px size-2 rounded-full border-[1.5px] border-solid border-slate-blue bg-powder-blue" />
            )}
        </div>
    );
}

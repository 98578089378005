import MemoApple from "@assets/SVG/Apple";
import MemoGoogle from "@assets/SVG/Google";
import MemoMeta from "@assets/SVG/Meta";
import MemoXBare from "@assets/SVG/XBare";

export default function SocialLogins() {
    const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;

    const startOAuth = (social: string) => {
        const OAUTH_URL =
            env === "production"
                ? `https://api.stytch.blockbeat.app/v1/public/oauth/${social}/start?public_token=public-token-live-c4dcc601-d672-46bd-bf96-a90e893dfae7&login_redirect_url=${window.location.origin}/authenticate?type=login&signup_redirect_url=${window.location.origin}/authenticate?type=signup`
                : `https://test.stytch.com/v1/public/oauth/${social}/start?public_token=public-token-test-78d47e1f-e7ca-4e8f-b89f-cf0c60b6be26&login_redirect_url=${window.location.origin}/authenticate?type=login&signup_redirect_url=${window.location.origin}/authenticate?type=signup`;

        // type was formerly type={} but now, it is passing signup or login, so we can know which one it is on authenticate page when mixpanel needs to send an analytic
        window.open(OAUTH_URL, "_blank", "width=600,height=400");
    };

    return (
        <div className="flex w-full flex-col items-center justify-center gap-3 p-0">
            <p className="text-center text-xs font-light text-white">or sign in with</p>
            <div className="flex w-full items-start gap-2 p-0">
                <a
                    onClick={() => startOAuth("twitter")}
                    className="flex h-10 w-full cursor-pointer items-center justify-center rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-75"
                >
                    <MemoXBare className="size-[15px]" />
                </a>
                <a
                    onClick={() => startOAuth("google")}
                    className="flex h-10 w-full cursor-pointer items-center justify-center rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-75"
                >
                    <MemoGoogle className="h-[13px] w-[15px]" />
                </a>
                <a
                    onClick={() => startOAuth("facebook")}
                    className="flex h-10 w-full cursor-pointer items-center justify-center rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-75"
                >
                    <MemoMeta className="h-[13px] w-[15px]" />
                </a>
                <a
                    onClick={() => startOAuth("apple")}
                    className="flex h-10 w-full cursor-pointer items-center justify-center rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-75"
                >
                    <MemoApple className="h-[13px] w-[15px]" />
                </a>
            </div>
        </div>
    );
}

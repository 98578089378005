import { SoundType } from "@/types/util";

const playSound = (volume: number, type?: SoundType) => {
    const sound =
        type === SoundType.BREAKING
            ? "/sounds/breaking.mp3"
            : type === SoundType.DELETE
              ? "/sounds/delete.mp3"
              : type === SoundType.NEW_NEWS
                ? "/sounds/new-news.mp3"
                : "/sounds/notification.wav";

    const audio = new Audio(sound);
    audio.volume = volume;
    audio.play();
};

export default playSound;

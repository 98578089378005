import { Modal as ReactModal } from "react-responsive-modal";
import { Button } from "@/components";
import { CheckCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import MemoLoading from "@assets/SVG/Loading";
import { Dispatch, SetStateAction } from "react";

export const ModalHeader = ({ success, errorType }: { success: boolean; errorType: string }) => {
    if (success) {
        return (
            <>
                <div className="flex size-9 items-center justify-center bg-vibrant-green/10">
                    <CheckCircleIcon className="size-5 text-vibrant-green" />
                </div>
                <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                    <h2 className="text-base text-white">Email Successfully sent!</h2>
                    <p className="text-center text-xs font-light text-neutral">
                        Please check your email inbox, including your spam folders, and follow the
                        instructions provided in the email.
                    </p>
                </div>
            </>
        );
    }

    switch (errorType) {
        case "email_not_found":
            return (
                <>
                    <div className="flex size-9 items-center justify-center bg-light-red/10">
                        <ExclamationTriangleIcon className="size-5 text-light-red" />
                    </div>
                    <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                        <h2 className="text-base text-white">Email Not Found.</h2>
                        <p className="text-center text-xs font-light text-neutral">
                            Please supply another email!
                        </p>
                    </div>
                </>
            );
        case "unauthorized_credentials":
            return (
                <>
                    <div className="flex size-9 items-center justify-center bg-light-red/10">
                        <ExclamationTriangleIcon className="size-5 text-light-red" />
                    </div>
                    <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                        <h2 className="text-base text-white">Incorrect Password</h2>
                        <p className="text-center text-xs font-light text-neutral">Please check password!</p>
                    </div>
                </>
            );
        case "reset_password":
            return (
                <>
                    <div className="flex size-9 items-center justify-center bg-light-red/10">
                        <ExclamationTriangleIcon className="size-5 text-light-red" />
                    </div>
                    <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                        <h2 className="text-base text-white">Password Needs Authentication</h2>
                        <p className="text-center text-xs font-light text-neutral">
                            Password requires a reset before successful authentication.
                        </p>
                    </div>
                </>
            );
        case "no_user_password":
            return (
                <>
                    <div className="flex size-9 items-center justify-center bg-light-red/10">
                        <ExclamationTriangleIcon className="size-5 text-light-red" />
                    </div>
                    <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                        <h2 className="text-base text-white">No associated Password</h2>
                        <p className="text-center text-xs font-light text-neutral">
                            Your account is associated with a social login. Please sign-in with any of the
                            social methods.
                        </p>
                    </div>
                </>
            );
        case "weak_password":
            return (
                <>
                    <div className="flex size-9 items-center justify-center bg-light-red/10">
                        <ExclamationTriangleIcon className="size-5 text-light-red" />
                    </div>
                    <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                        <h2 className="text-base text-white">Weak Password</h2>
                        <p className="text-center text-xs font-light text-neutral">
                            The password security is weak. Please supply another.
                        </p>
                    </div>
                </>
            );
        case "too_many_requests":
            return (
                <>
                    <div className="flex size-9 items-center justify-center bg-light-red/10">
                        <ExclamationTriangleIcon className="size-5 text-light-red" />
                    </div>
                    <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                        <h2 className="text-base text-white">Contact Administrator</h2>
                        <p className="text-center text-xs font-light text-neutral">
                            You have exceeded the maximum number of tries. Please try again in a few minutes
                            or contact the website administrator for assistance.
                        </p>
                    </div>
                </>
            );
        default:
            return (
                <>
                    <div className="flex size-9 items-center justify-center bg-light-red/10">
                        <ExclamationTriangleIcon className="size-5 text-light-red" />
                    </div>
                    <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                        <h2 className="text-base text-white">Error Sending Magic Link</h2>
                        <p className="text-center text-xs font-light text-neutral">Please retry!</p>
                    </div>
                </>
            );
    }
};

export const SignInConfirmationModal = ({
    show,
    setShow,
    success,
    loading,
    errorType,
}: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    success: boolean;
    loading: boolean;
    errorType: string;
}) => {
    const onCloseModal = () => setShow(false);

    return (
        <ReactModal
            open={show}
            onClose={onCloseModal}
            focusTrapped={false}
            center
            showCloseIcon={false}
            classNames={{
                modal: "modal-bg-none min-w-100 modal-radius-5 modal-padding-0 modal-margin-0",
            }}
        >
            <div className="w-100 flex flex-col items-center justify-center overflow-hidden rounded-lg bg-base-100 p-0">
                {loading ? (
                    <div className="flex h-44 items-center justify-center">
                        <MemoLoading className="w-4" />
                    </div>
                ) : (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            setShow(false);
                        }}
                        className="flex w-full flex-col gap-1"
                    >
                        <div className="flex w-full flex-col items-center gap-1 px-4 pb-5 pt-4">
                            <ModalHeader success={success} errorType={errorType} />
                        </div>
                        <div className="flex items-start justify-center gap-2 px-4 pb-3 pt-0">
                            <Button
                                type="submit"
                                onClick={() => setShow(false)}
                                className="w-45 flex h-10 items-center justify-center gap-2.5 rounded-lg border-none bg-powder-blue px-2.5 py-3"
                            >
                                <p className="text-xs font-normal text-white">Close this</p>
                            </Button>
                        </div>
                    </form>
                )}
            </div>
        </ReactModal>
    );
};

"use client";

import Link from "next/link";
import MemoFullBlockbeatLogo from "@assets/SVG/Logo/FullBlockbeatLogo";
import { useUserSubscription } from "@/services/payment";

export default function BlockBeatBetaLogo() {
    const { data } = useUserSubscription();
    const userIsPro = data?.subIsActive;

    return (
        <Link href="/dashboard" className="relative">
            {userIsPro && (
                <div className="absolute right-2.5 top-[5px] w-[fit-content] rounded-2xl border-[1.5px] border-solid border-slate-blue bg-light-red px-1 text-[8px] uppercase leading-[10px] text-white">
                    BETA
                </div>
            )}
            <MemoFullBlockbeatLogo className="mr-8 h-12 w-32 cursor-pointer hover:brightness-75" />
        </Link>
    );
}

import { Modal as ReactModal } from "react-responsive-modal";
import MemoFlame from "@assets/SVG/Flame";
import { isEmpty } from "lodash";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";

const NotificationItem = ({ notification }) => {
    return (
        <div className="flex flex-col items-start gap-2 px-4 py-2">
            <div className="flex w-full items-start gap-2 p-0">
                <div className="flex size-5 items-center justify-center rounded-[4px] bg-light-orange">
                    <MemoFlame className="size-2.5" color="#FFFFFF" />
                </div>
                <div className="flex w-full flex-col items-start justify-center gap-1 p-0">
                    <div className="flex w-full items-center justify-between px-0 py-0.5">
                        <p className="text-xs font-light text-white">{notification.type}</p>
                        <p className="text-[10px] font-light leading-3 text-neutral">2:56pm</p>
                    </div>
                    <p className="text-xs font-normal text-white">
                        {notification.headline}
                        <span className="ml-1 text-[10px] font-light leading-3 text-light-gray">
                            {notification.source}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export const NotificationModal = ({
    notifications,
    clearNotifications,
    show,
    setShow,
}: {
    notifications: any[];
    clearNotifications: () => void;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) => {
    const onCloseModal = () => setShow(false);

    return (
        <ReactModal
            open={show}
            focusTrapped={false}
            onClose={onCloseModal}
            closeIcon={<XMarkIcon className="size-4 text-neutral" strokeWidth={2.5} />}
            classNames={{
                modal: "max-h-screen modal-radius-5 modal-padding-0 modal-margin-0",
                modalContainer: "modal-container-right",
            }}
        >
            <div className="shadow-[-8px 0px 15px rgba(0, 0, 0, 0.2)] w-100 relative flex flex-col border-x border-dark-blue bg-slate-blue">
                <div className="flex w-full flex-col items-start border-b border-dark-blue px-4 py-3">
                    <div className="flex items-center justify-between gap-[149px] p-0">
                        <p className="text-base font-normal leading-5 text-white">Notifications</p>
                    </div>
                </div>

                {isEmpty(notifications) ? (
                    <div className="mt-8 flex flex-col items-center justify-center gap-2 pb-16 text-light-gray">
                        <ExclamationCircleIcon className="size-8" />
                        <p className="text-light-gray">No match</p>
                    </div>
                ) : (
                    <div className="flex size-full flex-col items-start overflow-y-scroll p-0 pb-24">
                        <div className="flex w-full flex-col">
                            <div className="flex w-full items-start gap-2 border-b border-dark-blue px-4 py-2">
                                <p className="text-xs text-neutral">Today</p>
                            </div>
                            <div className="divide-y-1 flex w-full flex-col divide-dark-blue">
                                {notifications.map((notification, i) => (
                                    <NotificationItem key={i} notification={notification} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                <div className="fixed bottom-0 flex w-full flex-col">
                    <div className="news-gradient flex h-14 w-full" />

                    <div
                        onClick={clearNotifications}
                        className="flex w-full cursor-pointer flex-col items-center gap-3 border-y border-dark-blue bg-slate-blue px-4 py-3 hover:brightness-75"
                    >
                        <div className="flex items-center justify-between gap-4 p-0">
                            <div className="flex items-center gap-0.5 p-0">
                                <div className="flex size-4 items-center justify-center">
                                    <div className="size-3 rounded-full bg-neutral" />
                                </div>
                                <p className="text-xs text-light-blue">Clear All</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};
